import {
    isWishlistEnabled,
    WishlistDispatcher as SourceWishlistDispatcher,
} from 'SourceStore/Wishlist/Wishlist.dispatcher';

import WishlistQuery from 'Query/Wishlist.query';
import { Wishlist } from 'Query/Wishlist.type';
import CartDispatcher from 'Store/Cart/Cart.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import { NotificationType, ShowNotificationAction } from 'Store/Notification/Notification.type';
import {
    clearWishlist,
    removeItemFromWishlist,
    updateAllProductsInWishlist,
    updateIsLoading,
} from 'Store/Wishlist/Wishlist.action';
import { NetworkError } from 'Type/Common.type';
import { GQLWishlistItemInput, GQLWishlistItemUpdateInput } from 'Type/Graphql.type';
import { isSignedIn } from 'Util/Auth/IsSignedIn';
import { getCartId, setCartId } from 'Util/Cart';
import CartQuery from 'Query/Cart.query';
import { getAuthorizationToken } from 'Util/Auth/Token';
import { getErrorMessage } from 'Util/Request/Error';
import { fetchMutation } from 'Util/Request/Mutation';
import { fetchQuery } from 'Util/Request/Query';
import { getStoreState } from 'Util/Store';
import { getPriceRange } from 'Util/Wishlist';
import agtm from './../../gtm/tags';

export {
    isWishlistEnabled,
};

/** @namespace AlmusbahblendPwa/Store/Wishlist/Dispatcher */
export class WishlistDispatcher extends SourceWishlistDispatcher {
    // TODO implement logic

    async addItemToWishlist(
        dispatch,
        options,
    ) {
        if (!isSignedIn()) {
            return;
        }

        try {
            const { items = [], wishlistId = '' } = options;

            dispatch(updateIsLoading(true));
            const {
                addProductsToWishlist: { user_errors },
            } = await fetchMutation(WishlistQuery.addProductsToWishlist(wishlistId, items));

            // console.log("add Whislist options: ", options);

            if (user_errors.length > 0) {
                user_errors.map(({ message }) => dispatch(
                    showNotification(
                        NotificationType.ERROR,
                        __('We can`t add the item to Wishlist right now: %s', message).toString(),
                    ),
                ));
            } else {
                dispatch(showNotification(NotificationType.SUCCESS, __('Product added to wish-list!')));
                await this._syncWishlistWithBE(dispatch);
            }
        } catch {
            dispatch(showNotification(NotificationType.ERROR, __('Error updating wish list!')));
        } finally {
            dispatch(updateIsLoading(false));
        }
    }

    async removeItemFromWishlist(
        dispatch,
        { item_id, noMessages },
    ) {
        if (!item_id || !isSignedIn()) {
            return Promise.reject();
        }

        dispatch(updateIsLoading(true));

        try {
            await fetchMutation(WishlistQuery.getRemoveProductFromWishlistMutation(item_id));
        } catch (e) {
            if (!noMessages) {
                dispatch(showNotification(NotificationType.ERROR, __('Error updating wish list!')));
            }

            return Promise.reject();
        }

        dispatch(removeItemFromWishlist(item_id));

        if (!noMessages) {
            dispatch(showNotification(NotificationType.SUCCESS, __('Product has been removed from your Wish List!')));
        }

        return Promise.resolve();
    }

    async getItemsInWishlist(toAdd = true) {
        // get wishlist items
        let productsToAdd = [];
        await fetchQuery(WishlistQuery.getWishlistQuery()).then(
            /** @namespace Store/Wishlist/Dispatcher/WishlistDispatcher/_syncWishlistWithBE/fetchQuery/then */
            (data) => {
                console.log(" moveWishlistToCart getWishlistQuery: ", data);
                if (data && data.wishlist) {
                    const { wishlist } = data;
                    wishlist.items.map((
                        wishlistItem,
                    ) => {
                        const {
                            id,
                            sku,
                            product,
                            description,
                            price,
                            buy_request,
                            options,
                            qty: quantity,
                        } = wishlistItem;

                        console.log("Wishlist Item:", product);

                        // const {
                        //     price_range: {
                        //         minimum_price: {
                        //             discount = 0,
                        //         } = {},
                        //     } = {},
                        // } = product;

                        // const priceRange = getPriceRange(product, price, price_without_tax, discount);

                        const result = {
                            ...product,
                            // ...priceRange,
                            quantity,
                            price,
                            wishlist: {
                                id,
                                sku,
                                quantity,
                                description,
                                buy_request,
                                options,
                            },
                        };

                        if ((product.type_id == "configurable" || product.stock_status != "IN_STOCK") && toAdd) {
                            // return {};
                        } else {
                            productsToAdd.push(result);
                        }
                        
                    }, {});

                    console.log(" All Items in Wishlist: ", productsToAdd);
                }
            },
            /** @namespace Store/Wishlist/Dispatcher/WishlistDispatcher/_syncWishlistWithBE/fetchQuery/then/catch */
            () => {
                dispatch(updateIsLoading(false));
            },
        );

        return productsToAdd;
    }

    async moveWishlistToCart(dispatch, sharingCode = '') {
        if (!isSignedIn()) {
            await Promise.reject();
        }

        const itemsInWishlist = await this.getItemsInWishlist(true);

        try {
            await fetchMutation(WishlistQuery.getMoveWishlistToCart(sharingCode));
        } finally {
            await this._syncWishlistWithBE(dispatch);
            itemsInWishlist.forEach(product => {
                this.gtmAddToCart(product);
            });
            // itemsInWishlist.map((product) => {
            //     this.gtmAddToCart(product);
            // });
            CartDispatcher.updateInitialCartData(dispatch, !!getAuthorizationToken());
            dispatch(showNotification(NotificationType.SUCCESS, __('Available items moved to cart')));
        }
    }

    async clearWishlist(dispatch) {
        if (!isSignedIn()) {
            return Promise.reject();
        }

        const itemsInWishlist = await this.getItemsInWishlist(false);

        return fetchMutation(WishlistQuery.getClearWishlist())
            .then(
                /** @namespace Store/Wishlist/Dispatcher/WishlistDispatcher/clearWishlist/then/catch/fetchMutation/then/dispatch */
                () => {
                    dispatch(clearWishlist());

                    itemsInWishlist.forEach(product => {
                        this.gtmRemoveFromWishlist(product);
                    });
                    // itemsInWishlist.map((product) => {
                    //     this.gtmRemoveFromWishlist(product);
                    // });
                },
            )
            .catch(
                /** @namespace Store/Wishlist/Dispatcher/WishlistDispatcher/clearWishlist/then/catch/dispatch */
                () => dispatch(showNotification(NotificationType.ERROR, __('Error clearing wish list!'))),
            );
    }

    async gtmAddToCart(product) {

        // const query = [ProductListQuery.getQuery(options)];
        // console.log("Cart adding Product Data: ", product);

        const addingProduct = product;
        var date_time = `${Math.floor(new Date() / 1000)}`;          
        let contentCategory = 'Adding To Cart'
        let contentType = 'product'
        let contentName = addingProduct.name
        let contentIds = [addingProduct.sku]
        let contents = [{id:addingProduct.sku , quantity:addingProduct.quantity}]

        agtm.cartPageEvent(
            date_time,
            addingProduct,
            "add",
            "WishlistPage",
            "AddAllToCartClick",
            'en',
            {date_time ,contentIds ,contents , contentName , contentType , contentCategory}
        );
    }

    async gtmRemoveFromWishlist(product) {

        // const query = [ProductListQuery.getQuery(options)];
        // console.log("Cart adding Product Data: ", product);

        const addingProduct = product;
        var date_time = `${Math.floor(new Date() / 1000)}`;          
        let contentCategory = 'Remove From Wishlist'
        let contentType = 'product'
        let contentName = addingProduct.name
        let contentIds = [addingProduct.sku]
        let contents = [{id:addingProduct.sku}]

        agtm.cartPageEvent(
            date_time,
            addingProduct,
            "removefromwishlist",
            "WishlistPage",
            "RemoveAllFromWishlistClick",
            'en',
            {date_time ,contentIds ,contents , contentName , contentType , contentCategory}
        );
    }

    async gtmCartViewOrUpdate() {
        // find SKU by cart item_id
        const cartId = getCartId() || '';
        let cartRequst = await fetchQuery(
            CartQuery.getCartQuery(
                cartId || '',
            ), 
        );

        if (cartRequst.cartData.total_quantity <= 0) {
            return;
        }

        let cartData = cartRequst.cartData;

        const date_time = `${Math.floor(new Date() / 1000)}`
        let contentCategory = 'Update Cart'
        let contentName = 'Update Cart'
        let contentType = 'product_group'
        let contentIds = []
        let contents = []
        for (let i in cartData.items) {
            // console.log(cartData.items[i])
            contentIds.push(cartData.items[i].sku)
            contents.push({ id: cartData.items[i].sku, quantity: cartData.items[i].quantity ? cartData.items[i].quantity : 1 })
        }
        agtm.cartViewOrUpdateEvent({
            products: cartData.items,
            summary: cartData.prices,
        }, 'updated', 'CartPage', 'en', { date_time, contentIds, contents, contentType, contentName, contentCategory });
    }
}

export default new WishlistDispatcher();
